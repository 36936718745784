<template>
  <div class="ma-4">
    <v-expansion-panels class="mb-2">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row>
            <v-col cols="auto">
              <span class="text-h6">RideShare Dashboard</span>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card>
            <v-card-text>
            RideShare is a carpooling service where you can either <strong>schedule a ride</strong> as a driver, or <strong>register for anavailable ride</strong> as a rider.
            Once you schedule or register for a ride, your information will show up here. Navigate using the sidebar, or the buttons below.
            <br>RideShare uses your student email to send notifications and updates about rides you have scheduled or rides you are registered for.
            <br><br>In its current state, RideShare can only be used to schedule church rides. In the future, we hope to expand the application to accomodate general carpooling needs.
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card v-if="showScheduledRides" class="mb-2">
      <v-card-title>Scheduled Rides</v-card-title>
      <v-card-subtitle>Rides that you as a driver have scheduled</v-card-subtitle>
      <available-rides-table :table-config="driverTableConfig" @submit="cancelRide" @hasNoData="showScheduledRides = false"></available-rides-table>
    </v-card>
    <v-card v-if="showRegisteredRides" class="mb-2">
      <v-card-title>Rides you are Registered For</v-card-title>
      <v-card-subtitle>Rides that you as a rider have registered for</v-card-subtitle>
      <available-rides-table :table-config="riderTableConfig" @hasNoData="showRegisteredRides = false" @submit="deregisterFromRide"></available-rides-table>
    </v-card>
    <v-card v-if="!showScheduledRides && !showRegisteredRides">
      <v-card-title>
        There's nothing here!
      </v-card-title>
      <v-card-subtitle>
        You have no upcoming scheduled rides, and you are not registered for any rides.
      </v-card-subtitle>
      <v-card-actions>
        <v-btn to="/ride-share/schedule-ride">Schedule a Ride</v-btn>
        <v-btn to="/ride-share/available-rides">View Available Rides</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
export default {
  components: {
    availableRidesTable: () => import('@/components/ride-share/availableRidesTable')
  },
  setup (props, { root }) {
    const showScheduledRides = ref(true)
    const showRegisteredRides = ref(true)
    function deregisterFromRide (ride) {
      let data = ride[0]
      let id = data._id

      // data.registeredRiders.splice(data.registeredRiders.indexOf(root.$store.state.user.email), 1)

      data.deregistering = true

      root.$feathers.service('/ride-share/available-rides').patch(id, data).then(() => {
        let snackbar = { active: true, color: 'success', timeout: 6000, text: 'Successfully deregistered from ride!' }
        root.$store.dispatch('main/snackbar', snackbar)
        location.reload()
      }).catch((err) => {
        let snackbar = { active: true, color: 'error', timeout: 6000, text: err.message }
        root.$store.dispatch('main/snackbar', snackbar)
      })
    }

    function cancelRide (ride) {
      let data = ride[0]
      let id = data._id

      if (confirm('Are you sure you want to cancel this ride?\nNote: All the riders will be notified by email')) {
        root.$feathers.service('/ride-share/available-rides').remove(id, data).then(() => {
          let snackbar = { active: true, color: 'success', timeout: 6000, text: 'Successfully cancelled ride!' }
          root.$store.dispatch('main/snackbar', snackbar)
          location.reload()
        }).catch((err) => {
          let snackbar = { active: true, color: 'error', timeout: 6000, text: err.message }
          root.$store.dispatch('main/snackbar', snackbar)
        })
      }
    }
    return {
      showScheduledRides,
      showRegisteredRides,
      cancelRide,
      deregisterFromRide,
      driverTableConfig: {
        submitButtonLabel: 'Cancel Ride',
        isDriver: true,
        isRider: false,
        showFullRides: true,
        showFilters: false,
        showDetailButton: true
      },
      riderTableConfig: {
        submitButtonLabel: 'Deregister from Ride',
        isDriver: false,
        isRider: true,
        showFullRides: true,
        showFilters: false,
        showDetailButton: true
      }
    }
  }
}
</script>
